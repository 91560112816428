<template>
    <div>
        <div class="text-left">
            <b-form-group v-if="!isEditMode">
                <label> <b>Email</b> <span class="text-danger">*</span></label>
                <b-form-input v-model="mail" :state="isMailValid" trim />
                <b-form-invalid-feedback :state="isMailValid">
                    Veuillez saisir une adresse e-mail valide
                </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group>
                <label> <b>Nom</b> <span class="text-danger">*</span> </label>
                <b-form-input
                    v-model="lastName"
                    :state="isLastNameValid"
                    trim
                ></b-form-input>
                <b-form-invalid-feedback :state="isLastNameValid">
                    Veuillez saisir le nom de l'utilisateur
                </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group>
                <label>
                    <b>Prénom</b> <span class="text-danger">*</span>
                </label>
                <b-form-input
                    v-model="firstName"
                    :state="isFirstNameValid"
                    trim
                >
                </b-form-input>
                <b-form-invalid-feedback :state="isFirstNameValid">
                    Veuillez saisir le prénom de l'utilisateur
                </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group>
                <label><b>Numéro de téléphone</b></label>
                <b-form-input v-model="phone" type="tel" trim></b-form-input>
            </b-form-group>
            <b-form-group v-if="credentialsOptions.length">
                <label><b>Niveau de droits</b></label>
                <b-form-select
                    v-model="credentials"
                    :options="credentialsOptions"
                />
            </b-form-group>
            <label>
                <b>Projets associés</b>
            </label>
            <div class="k-flex-row k-flex-fill">
                <b-form-group>
                    <label> <b>Projet</b> </label>
                    <b-form-select
                        v-model="selectedProjectId"
                        value-field="id"
                        :options="projects"
                        text-field="name"
                    >
                        <template #first>
                            <b-select-option :value="null" disabled>
                                -- Choisir un projet --
                            </b-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
                <b-form-group>
                    <label> <b>Rôle</b> </label>
                    <b-select
                        v-model="selectedRole"
                        :options="roleList"
                        :disabled="!selectedProjectId"
                    >
                        <template #first>
                            <b-select-option :value="null" disabled>
                                -- Choisir le rôle --
                            </b-select-option>
                        </template>
                    </b-select>
                </b-form-group>
                <b-form-group>
                    <label> <b>Droits</b> </label>
                    <b-select
                        v-model="selectedRight"
                        :options="projectCredentialsOptions"
                        :disabled="!selectedRole"
                    >
                        <template #first>
                            <b-select-option :value="null" disabled>
                                -- Choisir les droits --
                            </b-select-option>
                        </template>
                    </b-select>
                </b-form-group>
                <div class="text-center">
                    <b-button variant="kalkin-2 mt-3" @click="addProjectUser()">
                        <b-icon-folder-plus /> Associer
                    </b-button>
                </div>
            </div>
            <b-form-group>
                <b-table
                    class="text-break mt-2"
                    :items="selectedProjects"
                    :fields="fields"
                    show-empty
                    empty-text="Aucun projet associé"
                    stacked="md"
                    hover
                    thead-class="text-center text-nowrap"
                    tbody-tr-class="pointer"
                    striped
                >
                    <template #table-busy>
                        <div class="text-center my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong class="ml-2"
                                >Chargement des données...</strong
                            >
                        </div>
                    </template>
                    <template #cell(createdAt)="data">
                        {{ data.item.createdAt | dateFromISO(true) }}
                    </template>
                    <template #cell(delete)="row">
                        <button
                            type="button"
                            v-b-tooltip.right.v-danger
                            title="Supprimer"
                            class="btn btn-sm btn-outline-danger"
                            @click="onDelete(row.item)"
                        >
                            <b-icon-trash />
                        </button>
                    </template>
                </b-table>
            </b-form-group>
        </div>
        <div v-if="!isEditMode" class="text-secondary font-italic mb-3">
            <small>
                <b-icon-info-circle /> L'utilisateur recevra un courriel à
                l'adresse e-mail fournie contenant les instructions qui lui
                permettront de finaliser son inscription, et notamment de
                choisir son mot de passe.
            </small>
        </div>
        <div class="text-center">
            <b-button
                v-if="!isEditMode"
                variant="kalkin-2"
                @click="createUser()"
            >
                <b-icon-person-plus-fill /> Créer l'utilisateur
            </b-button>
        </div>
    </div>
</template>
<script>
import validator from "@/tools/validator";

export default {
    props: {
        user: { type: Object, required: false },
    },

    data: function() {
        return {
            isEditMode: false,
            mail: null,
            firstName: null,
            lastName: null,
            phone: "",
            userProjects: [],
            projects: [],
            credentials: null,
            projectCredentialsOptions: ["Lecture", "Ecriture"],
            roleList: [
                "Chef de Projet",
                "Commercial",
                "Gestion des données",
                "Gestion de l'application",
                "Designer",
                "Développeur",
                "Testeur",
                "Porteur de projet",
                "Client",
                "Agent d'accueil",
                "Responsable",
                "Stagiaire",
            ],
            selectedProjectId: null,
            selectedProjects: [],
            selectedRight: null,
            selectedRole: null,
            credentialsOptions: [],
            fields: [
                {
                    key: "project.id",
                    label: "Identifiant",
                    sortable: true,
                    tdClass: "text-center",
                },
                {
                    key: "project.name",
                    label: "Nom",
                    sortable: true,
                    tdClass: "text-kalkin-1 font-weight-bold",
                },
                {
                    key: "role",
                    label: "Rôle",
                    tdClass: "text-center",
                    sortable: true,
                },
                {
                    key: "right",
                    label: "Droits",
                    tdClass: "text-center",
                    sortable: true,
                },
                {
                    key: "delete",
                    tdClass: "text-center",
                    label: "",
                },
            ],
        };
    },

    computed: {
        selectedProjectObject() {
            return this.projects.find(
                project => project.id === this.selectedProjectId,
            );
        },

        isMailValid() {
            if (this.mail === null) {
                return null;
            } else {
                return validator.validateEmail(this.mail);
            }
        },

        isLastNameValid() {
            if (this.lastName === null) {
                return null;
            } else {
                return this.lastName !== "";
            }
        },

        isFirstNameValid() {
            if (this.firstName === null) {
                return null;
            } else {
                return this.firstName !== "";
            }
        },

        isValid() {
            return (
                this.isMailValid &&
                this.isLastNameValid &&
                this.isFirstNameValid
            );
        },
    },

    methods: {
        async fetchProjects() {
            this.projects = await this.$store.dispatch(
                "project/getFSPProjects",
                {
                    sortby: "name",
                },
            );
        },

        initCredentialsOptions() {
            const me = this.$store.state.user.user;
            let base = [
                { value: -2, html: "Suspendu" },
                { value: -1, html: "Désactivé" },
                { value: 2, html: "Membre basique" },
                { value: 3, html: "Membre vérifié" },
                { value: 6, html: "Kalkin Consultant" },
                { value: 7, html: "Kalkin Manager" },
                { value: 8, html: "Kalkin Développeur" },
                { value: 10, html: "Admin" },
                { value: 42, html: "SuperAdmin" },
            ];

            // Si je ne suis pas Kalkin, accès interdit aux creds < 1
            if (me.credentials < 5) {
                base = base.filter(row => row.value >= 1);
            }
            // Dans tous les cas, accès refusé aux creds >= à me
            base = base.filter(row => row.value < me.credentials);

            return base;
        },

        createUser: async function() {
            if (this.isValid) {
                const newUser = await this.$store.dispatch("user/createUser", {
                    mail: this.mail,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    phone: this.phone,
                    credentials: this.credentials,
                });
                Object.values(this.selectedProjects).forEach(project => {
                    this.$store.dispatch("project/addUser", {
                        projectId: project.project.id,
                        userId: newUser.id,
                        role: project.role,
                        credentials: project.right == "Ecriture" ? 5 : 1,
                    });
                });
                this.$router.push({ name: "AdminListUsers" });
            } else {
                this.mail = this.mail || "";
                this.firstName = this.firstName || "";
                this.lastName = this.lastName || "";
            }
        },

        addProjectUser() {
            if (
                this.selectedProjectObject &&
                this.selectedRight &&
                this.selectedRole
            ) {
                const selectedProjectObject = {
                    project: this.selectedProjectObject,
                    right: this.selectedRight,
                    role: this.selectedRole,
                };
                this.selectedProjects.push(selectedProjectObject);
                this.selectedProjectId = null;
                this.selectedRight = null;
                this.selectedRole = null;
            }
        },

        onDelete(item) {
            const index = this.selectedProjects.findIndex(
                object => object.project.id === item.project.id,
            );
            if (index !== -1) {
                this.selectedProjects.splice(index, 1);
            }
        },
    },

    async beforeMount() {
        await this.fetchProjects();
        this.credentialsOptions = this.initCredentialsOptions();
    },
};
</script>
