<template>
    <div class="k-page-flex">
        <div class="k-page-title">
            <h2>Créer un utilisateur</h2>
            <b-button
                size="sm"
                variant="outline-secondary"
                @click="goListUsers"
            >
                <b-icon-list-ul /> Liste des utilisateurs
            </b-button>
        </div>

        <div class="k-page-body">
            <FormUser />
        </div>
    </div>
</template>
<script>
import FormUser from "@/components/model/user/FormUser";
export default {
    components: {
        FormUser,
    },

    methods: {
        goListUsers() {
            this.$router.push({
                name: "AdminListUsers",
            });
        },
    },
};
</script>
